import type { ComplexStyleRule } from '@vanilla-extract/css';
import type { RuntimeFn } from '@vanilla-extract/recipes';
import { omit, pick } from 'remeda';
import type { Simplify } from 'type-fest';

import {
  type RainbowSprinkles,
  type RainbowSprinklesResult,
  rainbowSprinkles,
} from '../rainbow-sprinkles.css.js';
import { joinClassnames, logUnknownProps } from './internal.js';

type Resolve<T> = {
  [Key in keyof T]: T[Key];
} & object;
type RecipeStyleRule = ComplexStyleRule | string;
type VariantDefinitions = Record<string, RecipeStyleRule>;
type BooleanMap<T> = T extends 'true' | 'false' ? boolean : T;
type VariantGroups = Record<string, VariantDefinitions>;
type VariantSelection<Variants extends VariantGroups> = {
  [VariantGroup in keyof Variants]?: BooleanMap<keyof Variants[VariantGroup]>;
};

type InferVariants<RecipeFn extends RuntimeFn<VariantGroups>> =
  RecipeFn extends RuntimeFn<infer Variants> ? Variants : never;

type InferVariantProps<RecipeFn extends RuntimeFn<VariantGroups>> =
  RecipeFn extends RuntimeFn<infer Variants> ?
    Simplify<Resolve<VariantSelection<Variants>>>
  : never;

type InferVariantKeys<RecipeFn extends RuntimeFn<VariantGroups>> =
  (keyof InferVariants<RecipeFn>)[];

function getVariantKeys<
  R extends RuntimeFn<VariantGroups>,
  VariantKeys extends Simplify<InferVariantKeys<R>>,
>(recipeFn: R): Simplify<VariantKeys> {
  return recipeFn.variants() as Simplify<VariantKeys>;
}

export function getRecipeStyleProps<
  RecipeFn extends RuntimeFn<VariantGroups>,
  VariantProps extends InferVariantProps<RecipeFn>,
  Props extends VariantProps & Record<string, unknown>,
>(recipeFn: RecipeFn, props: Props, css?: Simplify<RainbowSprinkles>) {
  const variantKeys = getVariantKeys(recipeFn);
  const variantProps = pick(
    props,
    variantKeys as readonly (keyof typeof props)[],
  );
  const otherProps = omit(
    props,
    variantKeys as readonly (keyof typeof props)[],
  ) as Simplify<Omit<typeof props, (typeof variantKeys)[number]>>;

  const classNameList = [recipeFn(variantProps)];

  let style: RainbowSprinklesResult['style'] | undefined;

  if (css) {
    const result = rainbowSprinkles(css);

    classNameList.push(result.className);
    style = result.style;

    logUnknownProps(result.otherProps);
  }

  return {
    className: joinClassnames(classNameList),
    style,
    otherProps,
  };
}
